<template>
  <div class="page_box">
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="box_o">
      <div class="title_box">
        <div class="title_o">
          共建SaaS新生态 赋能企业数字化转型
        </div>
        <div class="tit_o">
          JEPaaS云平台 给你无限可能
        </div>
      </div>
      <div class="title_o">
        合作方式
      </div>
      <div class="list_con">
        <div class="ioc_con">
          <div class="ico_img">
            <img
              v-lazy="imgo_1"
              alt=""
            >
          </div>
          <div class="ico_tit">
            VIP服务
          </div>
          <div class="ico_text">
            基于JEPaaS平台购买及源码买断，为客户提供系统维护与升级。
          </div>
        </div>
        <div class="ioc_con">
          <div class="ico_img">
            <img
              v-lazy="imgo_2"
              alt=""
            >
          </div>
          <div class="ico_tit">
            定制一对一服务
          </div>
          <div class="ico_text">
            基于JEPaaS平台与客户需求，提供信息化解决方案及实施服务，同时，为客户提供系统维护与升级。
          </div>
        </div>
        <div class="ioc_con">
          <div class="ico_img">
            <img
              v-lazy="imgo_3"
              alt=""
            >
          </div>
          <div class="ico_tit">
            优势合作对赌
          </div>
          <div class="ico_text">
            基于JEPaaS平台技术优势，以及客户方资源（但不限于）等优势，进行合作，利润共享的合作模式。
          </div>
        </div>
        <div class="ioc_con">
          <div class="ico_img">
            <img
              v-lazy="imgo_4"
              alt=""
            >
          </div>
          <div class="ico_tit">
            城市合伙人
          </div>
          <div class="ico_text">
            JEPaaS平台全国代理模式，即产品代理，销售利润分成模式。
          </div>
        </div>
      </div>
    </div>
    <div class="box_s">
      <div class="title_s">
        客户评价
      </div>
      <div
        class="toast_con"
        :class="`toast_con${indexS}`"
      >
        <div class="con_top">
          {{ listArr[indexS].title }} {{ listArr[indexS].name }}
        </div>
        <div class="con_bott">
          {{ listArr[indexS].data }}
        </div>
      </div>
      <div class="portrait_con">
        <div
          v-for="item in listArr"
          :key="item.id"
          class="but_con"
        >
          <div
            class="img_s"
            :style="{background:(item.active?'rgba(255,48,65,1)':'#fff')}"
            @click="toggTab(item)"
          >
            <img
              v-lazy="item.imgUrl"
              alt=""
            >
          </div>
          <div class="but_name">
            {{ item.name }}
          </div>
          <div class="but_data">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="box_th">
      <div class="title_th">
        加入JEPaaS合伙伙伴阵营合作共赢 共创未来
      </div>
      <div class="from_con">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="70px"
          class="demo-ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="姓名"
                prop="name"
              >
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入您的姓名"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职务">
                <el-input
                  v-model="ruleForm.duty"
                  placeholder="请输入您的职务"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="12">
              <el-form-item
                label="电话"
                prop="phone"
              >
                <el-input
                  v-model="ruleForm.phone"
                  placeholder="请输入联系电话"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱">
                <el-input
                  v-model="ruleForm.email"
                  placeholder="请输入邮箱地址"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20 el_li"
            :gutter="20"
          >
            <el-col :span="24">
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="地址"
                    prop="provinceValue"
                  >
                    <el-select
                      v-model="ruleForm.provinceValue"
                      placeholder="省份"
                      @change="provinceChange"
                    >
                      <el-option
                        v-for="(item,index) in province"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    class="text-center"
                    prop="cityValue"
                  >
                    <el-select
                      v-model="ruleForm.cityValue"
                      :disabled="cityStatus"
                      placeholder="城市"
                      @change="cityChange"
                    >
                      <el-option
                        v-for="(item,index) in city"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="countyValue">
                    <el-select
                      v-model="ruleForm.countyValue"
                      :disabled="countyStatus"
                      placeholder="区县"
                      @change="countyChange"
                    >
                      <el-option
                        v-for="(item,index) in county"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item label="公司">
                <el-input
                  v-model="ruleForm.company"
                  placeholder="请输入公司名称"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item label="其他">
                <el-input
                  v-model="ruleForm.more"
                  type="textarea"
                  :rows="5"
                  placeholder="其他您想要告诉我们的"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div
            class="buttonShen"
            @click="submitForm('ruleForm')"
          >
            马上加入
          </div>
        </el-form>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script >
import areac from '@/assets/js/areac';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import bg_co from '@/assets/imgs/cooperation/bg_co.png';
import imgo_1 from '@/assets/imgs/cooperation/imgo_1.png';
import imgo_2 from '@/assets/imgs/cooperation/imgo_2.png';
import imgo_3 from '@/assets/imgs/cooperation/imgo_3.png';
import imgo_4 from '@/assets/imgs/cooperation/imgo_4.png';
import imgs_1 from '@/assets/imgs/cooperation/imgs_1.png';
import imgs_2 from '@/assets/imgs/cooperation/imgs_2.png';
import imgs_3 from '@/assets/imgs/cooperation/imgs_3.png';
import imgs_4 from '@/assets/imgs/cooperation/imgs_4.png';

export default {
  name: 'CooperationPage',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      bg_co,
      imgo_1,
      imgo_2,
      imgo_3,
      imgo_4,
      imgs_1,
      imgs_2,
      imgs_3,
      imgs_4,
      prohibit: true,
      province: [],
      provinceIndex: null, // 省的下标
      city: [],
      cityIndex: null, // 城市的下标
      cityStatus: true,
      county: [],
      countyStatus: true,
      countyIndex: null, // 区县的下标
      /** ******************表单的验证************** */
      ruleForm: {
        name: '',
        duty: '',
        phone: '',
        email: '',
        company: '',
        more: '',
        provinceValue: '',
        provinceCode: '',
        cityValue: '',
        cityCode: '',
        countyValue: '',
        address: '',
        areaCode: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入您的联系电话', trigger: 'blur' },
        ],
        provinceValue: [
          { required: true, message: '请选择您的省份', trigger: 'change' },
        ],
        cityValue: [
          { required: true, message: '请选择您的城市', trigger: 'change' },
        ],
        countyValue: [
          { required: true, message: '请选择您的区县', trigger: 'change' },
        ],
      },
      listArr: [
        {
          id: 0,
          active: 1,
          imgUrl: imgs_1,
          name: '谈云键',
          title: '华信咨询设计研究院负责人 ',
          data: 'JEPaaS作为一个成熟的软件快速开发平台，支持mysql和oracle，可以在短时间内以低成本快速完成一个项目。在平台的使用中，许多非常有用又有趣的功能逐渐被发掘，比如软件打包升级功能，使上线成本得以大幅度减轻；还有JEPaaS手机APP开发的功能，开发一次，安卓和ios都可使用，确实值得点赞！',
        },
        {
          id: 1,
          active: 0,
          imgUrl: imgs_2,
          name: '朱常雄',
          title: '国泰君安机构委架构师 ',
          data: 'JEPaaS平台为提高管理类系统的开发效率而生！目前我们的系统有1000多个功能，至少80%的功能是通过JEPaaS直接配置出来的，高效的图表引擎和工作流引擎，对我们项目的提供了极大的便利；同时JEPaaS还支持自定义开发，平台自带了大量易懂易用的API,可根据业务需求来自定义页面的开发，并能很方便的集成到平台中来。',
        },
        {
          id: 2,
          active: 0,
          imgUrl: imgs_3,
          name: '贾彪',
          title: '中科聚信执行副总裁 ',
          data: 'JEPaaS是我司使用的第一个快速开发平台。我司使用JEPaaS开发并上线了“队伍建设管理系统”、'
          + '"综合设备管理系统"等系统。项目成功上线后，通过对比原纯代码的开发模式，我司一致认为JEPaaS是最好、最适合的快速开发平台，'
          + '特别是对数据库的操作，可以快速对数据表的字段、类型进行修改并能及时的呈现到页面和数据录入上，'
          + '还有后台的动态bean省下了大量写实体类的时间，可以快速的获取前台传到后台的数据。可以说，JEPaaS是初创公司及软件工坊的首选平台，'
          + '第一节约人工成本，第二开发周期短、成品见效快、平台稳定成熟。',
        },
        {
          id: 3,
          active: 0,
          imgUrl: imgs_4,
          name: '王文华',
          title: '云南千寻科技有限公司负责人 ',
          data: '我们用JEPaaS开发了一个站式服务平台。多亏有了JEPaaS，我们才能这么快就搭建了属于自己的平台。不需要太多专业知识，只需跟着视频教程就能上手开发，扩展性强，功能也多，最重要的是还有自带的APP，完全可能实现移动办公，太方便了！',
        },
      ],
      indexS: 0,
    };
  },
  created() { // 创建vue实例后
    this.getCity();
    this.prohibit = true;
  },
  destroyed() { // vue实例销毁后

  },
  methods: { // 事件
    toggTab(e) {
      const self = this;
      self.indexS = e.id;
      self.listArr.filter(item => (e.id === item.id ? item.active = true : item.active = false));
    },
    areaNo() {
      this.addressShow = false;
    },
    // 注册地址选择成功
    areaOk(data) {
      const self = this;
      let city = '';
      data.forEach((val, index) => {
        if (index !== 2) {
          city += `${val.name},`;
        } else {
          city += `${val.name}`;
        }
      });
      self.ruleForm.provinceValue = data[0].name;
      self.ruleForm.provinceCode = data[0].code;
      self.ruleForm.cityValue = data[1].name;
      self.ruleForm.cityCode = data[1].code;
      self.ruleForm.countyValue = data[2].name;
      self.ruleForm.areaCode = data[2].code;
      this.ruleForm.address = city;
      this.addressShow = false;
    },
    // 点击选中县城
    countyChange(val) {
      this.ruleForm.countyValue = this.county[val].label;
      this.ruleForm.areaCode = this.county[val].value;
    },
    // 点击选中市
    cityChange(val) {
      this.cityIndex = val;
      this.ruleForm.countyValue = '';
      this.ruleForm.areaCode = '';
      this.county = this.province[this.provinceIndex].children[val].children;
      this.countyStatus = false;
      this.ruleForm.cityValue = this.city[val].label;
      this.ruleForm.cityCode = this.city[val].value;
    },
    // 点击选中省
    provinceChange(val) {
      this.provinceIndex = val;
      this.county = '';
      this.ruleForm.cityValue = '';
      this.ruleForm.countyValue = '';
      this.ruleForm.cityCode = '';
      this.ruleForm.areaCode = '';
      this.city = this.province[val].children;
      this.cityStatus = false;
      this.ruleForm.provinceValue = this.province[val].label;
      this.ruleForm.provinceCode = this.province[val].value;
    },
    // 获取三级联查的各个地区
    getCity() {
      this.province = areac.data.filter(item => [
        item.value,
        item.label,
      ]);
    },
    // 马上加入
    submitForm(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (self.prohibit) {
            self.prohibit = false;
            self.$ajax({
              url: '/je/product/crm/customer/saveSaleLead',
              data: {
                typeCode: 'jepaas',
                name: self.ruleForm.name,
                telePhone: self.ruleForm.phone,
                corporateName: self.ruleForm.company,
                provinceName: self.ruleForm.provinceValue,
                provinceCode: self.ruleForm.provinceCode,
                cityName: self.ruleForm.cityValue,
                cityCode: self.ruleForm.cityCode,
                areaName: self.ruleForm.countyValue,
                areaCode: self.ruleForm.areaCode,
                duties: self.ruleForm.duty,
                email: self.ruleForm.email,
                describe: self.ruleForm.more,
              },
            }).then(() => {
              self.prohibit = true;
              this.$message.success('提交成功请静候佳音!');
              self.ruleForm.name = '';
              self.ruleForm.phone = '';
              self.ruleForm.company = '';
              self.ruleForm.provinceValue = '';
              self.ruleForm.provinceCode = '';
              self.ruleForm.cityValue = '';
              self.ruleForm.cityCode = '';
              self.ruleForm.countyValue = '';
              self.ruleForm.areaCode = '';
              self.ruleForm.duty = '';
              self.ruleForm.email = '';
              self.ruleForm.more = '';
              this.$refs[formName].resetFields();
            }).catch(() => {

            });
          }
        } else {
          this.$message.error('请将所有必填信息填写完整');
          return false;
        }
      });
    },
  },
};
</script>

<style lang='less' scoped >
.page_box {
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .box_o {
    width: 1200px;
    text-align: center;
    margin: 100px auto;
    .title_box {
      width: 1200px;
      height: 180px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-top: -30px;
      .title_o {
        font-size: 36px;
        font-weight: bold;
        color: rgba(0, 0, 0, 1);
        padding: 48px 0 20px 0;
      }
      .tit_o {
        height: 24px;
        font-size: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
    }
    .title_o {
      height: 38px;
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      padding: 130px 0 100px 0;
    }
    .list_con {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-bottom: 120px;
      .ioc_con {
        width: 210px;
        .ico_img {
          width: 178px;
          margin: 0 auto;
          height: 165px;
          img {
            width: 178px;
            height: 165px;
          }
        }
        .ico_tit {
          font-size: 24px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          padding: 30px 0 20px 0;
        }
        .ico_text {
          text-align: justify;
          font-size: 18px;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          line-height: 24px;
        }
      }
    }
  }
  .box_s {
    text-align: center;
    width: 100%;
    height: 837px;
    background: rgba(29, 34, 49, 1);
    .title_s {
      font-size: 40px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      padding: 120px 0 80px 0;
    }
    .toast_con {
      width: 1200px;
      margin: 0 auto;
      padding: 0 60px;
      height: 267px;
      background: rgba(255, 48, 65, 1);
      box-shadow: 7px 7px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      text-align: left;
      .con_top {
        font-size: 24px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        padding: 20px 0;
      }
      .con_bott {
        font-size: 18px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
      }
    }
    .toast_con::before {
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border: 20px solid transparent;
      border-top-color: rgba(255, 48, 65, 1);
      position: relative;
      bottom: -265px;
      // left: 6%;
    }
    .toast_con0:before {
      left: 6%;
    }
    .toast_con1:before {
      left: 35%;
    }
    .toast_con2:before {
      left: 61%;
    }
    .toast_con3:before {
      left: 89%;
    }
    .portrait_con {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .but_con {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        padding-top: 50px;
        .img_s {
          cursor: pointer;
          width: 120px;
          height: 120px;
          background: #fff;
          box-shadow: 7px 7px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .but_name {
          font-size: 18px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 24px;
          padding: 15px 0;
        }
        .but_data {
          font-size: 18px;
          font-weight: 400;
          color: rgba(204, 204, 204, 1);
          line-height: 24px;
        }
      }
    }
  }
  .box_th {
    text-align: center;
    .title_th {
      width: 479px;
      margin: 0 auto;
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      padding: 100px 0px 0;
    }
    .from_con {
      width: 950px;
      height: 680px;
      margin: 60px auto;
      padding: 100px 90px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      text-align: left;
      .mt-20 {
        margin-top: 20px;
      }
      .buttonShen {
        cursor: pointer;
        width: 480px;
        margin: 90px auto;
        height: 60px;
        background: rgba(255, 48, 65, 1);
        box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
        border-radius: 30px;
        line-height: 60px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(254, 255, 255, 1);
        text-align: center;
        position: relative;
      }
      .buttonShen::after {
        content: "";
        width: 480px;
        height: 60px;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 30px;
        z-index: -1;
        transition: background 0.7s;
      }
      .buttonShen:hover:after {
        z-index: 2;
        background: rgba(51, 51, 51, 0.1);
      }
    }
  }
}
</style>
